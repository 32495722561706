<template>
  <div class="EnterpriseQualification">
    <div class="qualificationBg"></div>
    <div class="label">废危品经营许可证</div>
    <div class="btnClass">
      <div
        class="start pub"
        @click="handleStart"
        v-if="
          qualificationList.length == 0 ||
            qualificationList[0].authenticationStatus == 0
        "
      >
        开始认证
      </div>
      <div
        class="inProgress pub"
        v-if="
          qualificationList.length != 0 &&
            qualificationList[0].authenticationStatus == 1
        "
      >
        认证中...
      </div>
      <div
        class="verified pub"
        v-if="
          qualificationList.length != 0 &&
            qualificationList[0].authenticationStatus == 2
        "
      >
        已认证
      </div>
      <div
        @click="handleReapplication"
        class="reStart pub"
        v-if="
          qualificationList.length != 0 &&
            (qualificationList[0].authenticationStatus == 3 ||
              qualificationList[0].authenticationStatus == 4)
        "
      >
        重新申请
      </div>
    </div>

    <div
      v-if="
        qualificationList.length != 0 &&
          qualificationList[0].authenticationStatus == 2
      "
      class="termValidity"
    >
      {{
        `${qualificationList[0].expirationDateStart}至${qualificationList[0].expirationDateEnd}`
      }}
    </div>

    <div
      class="failReason"
      v-if="
        qualificationList.length != 0 &&
          (qualificationList[0].authenticationStatus == 3 ||
            qualificationList[0].authenticationStatus == 4)
      "
    >
      失败原因：{{ qualificationList[0].failReason }}
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="废危品经营许可证"
      :visible.sync="open"
      width="600px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="许可证编号" prop="permitCode">
          <el-input
            v-model="ruleForm.permitCode"
            placeholder="请输入许可证编号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="法人名称" prop="companyName">
          <el-input v-model="ruleForm.companyName" placeholder="请输入法人名称">
          </el-input>
        </el-form-item>
        <el-form-item label="法定代表人" prop="legalName">
          <el-input v-model="ruleForm.legalName" placeholder="请输入法定代表人">
          </el-input>
        </el-form-item>
        <el-form-item label="经营地区" prop="address">
          <el-select v-model="ruleForm.address" placeholder="请选择">
            <el-option
              v-for="item in provinceJSON"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效期限" prop="validityPeriod">
          <el-date-picker
            v-model="ruleForm.validityPeriod"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="证件图片" prop="pictureName">
          <el-upload
            v-model="ruleForm.pictureName"
            class="upload-demo"
            :headers="headers"
            :limit="1"
            drag
            accept=".png, .jpg, .jpeg"
            :action="baseUrl + '/file/uploadFile'"
            multiple
            :data="uploadImage"
            :file-list="ruleForm.pictureName"
            :on-remove="handlePictureRemove"
            :on-exceed="handlePictureExceed"
            :on-change="handlePictureChange"
            :before-upload="beforeUploadImage"
            list-type="picture"
          >
            <em class="el-icon-upload"></em>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              最多可上传1张照片，支持png,jpg,jpeg
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          style="display: flex; justify-content: end;margin-bottom: 0;"
        >
          <el-button type="primary" @click.prevent="submitPass()"
            >确认</el-button
          >
          <el-button @click.prevent="resetForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import provinceJSON from "@/mock/province.json";
import { getToken } from "@/utils/token";
import { deleteFile } from "@/api/workSpace";
import { getAuthenticationlist, insertManuQualifications } from "@/api/account";
export default {
  name: "EnterpriseQualification",
  data() {
    return {
      qualificationList: [
        // {
        //   authenticationName: "", //资质证名称
        //   authenticationStatus: "", //资质认证状态
        // },
      ],
      headers: { Authorization: "Bearer " + getToken() },
      baseUrl: "/api",
      uploadImage: {},

      open: false,
      ruleForm: {
        pictureName: [],
      },
      provinceJSON,
      dateRange: [],
      rules: {
        permitCode: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            pattern: /^[a-zA-Z0-9\-]{1,30}$/,
            message: "只可录入数字，字母及“-”，最大录入30位",
            trigger: "change",
          },
        ],
        companyName: [
          { required: true, message: "请输入法人名称", trigger: "change" },
          {
            max: 40,
            message: "最多输入40字",
            trigger: "change",
          },
        ],
        legalName: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            max: 20,
            message: "最多输入40字",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请选择经营地区", trigger: "change" },
        ],
        validityPeriod: [
          { required: true, message: "请选择有效期限", trigger: "change" },
        ],
        pictureName: [
          { required: true, message: "请上传资质相关图片", trigger: "change" },
        ],
        // checkPass: [
        //   { required: true, validator: validatePass2, trigger: "blur" },
        // ],
      },
    };
  },
  watch: {
    "ruleForm.pictureName": {
      handler(newVal) {
        if (newVal.length) {
          this.$refs["ruleForm"].clearValidate(["pictureName"]);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.$store.dispatch("getUserInfo");
    this.getStatus();
  },
  methods: {
    getStatus() {
      getAuthenticationlist({ manuId: this.userInfo.manuId }).then((res) => {
        this.qualificationList = res.data;
      });
    },

    handleStart() {
      this.open = true;
    },
    handleReapplication() {
      this.open = true;
      getAuthenticationlist({ manuId: this.userInfo.manuId }).then((res) => {
        let obj = res.data[0];
        obj.validityPeriod = [
          res.data[0].expirationDateStart,
          res.data[0].expirationDateEnd,
        ];
        const result = obj.objectName.split("/").pop();
        console.log(result);
        console.log(obj.objectName);
        obj.pictureName = [];
        obj.pictureName.push({
          name: result,
          url:
            this.baseUrl + "/file/displayResource?objectName=" + obj.objectName,
        });
        this.ruleForm = obj;
        console.log(this.ruleForm.pictureName);
      });
    },

    // 图片限制
    handlePictureExceed() {
      this.$message({
        message: "最多只能上传一张图片！",
        type: "warning",
      });
    },

    // 图片change
    handlePictureChange(file, fileList) {
      this.ruleForm.pictureName = fileList;
    },

    // 上传图片之前的函数
    beforeUploadImage(file) {
      // .png, .jpg, .jpeg
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
      if (!extension) {
        this.$message({
          type: "warning",
          message: "图片上传格式不正确！",
        });
        return extension;
      } else {
        this.uploadImage.objectName =
          `/apply/application/${this.userInfo.manuId}/` + file.name;
      }
    },

    //删除图片
    handlePictureRemove(file, fileList) {
      if (file && file.status === "success") {
        deleteFile({
          objectName: `/apply/application/${this.userInfo.manuId}/` + file.name,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.ruleForm.pictureName = fileList;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }
    },

    submitPass() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 判断有效期至时间和当天，小于当天提醒过期
          if (
            this.ruleForm.validityPeriod[1] <
            new Date().toISOString().split("T")[0]
          ) {
            this.$message({
              type: "warning",
              message: "您的废危品经营许可证已过期，不可提交！",
            });
          } else if (
            this.ruleForm.validityPeriod[0] >
            new Date().toISOString().split("T")[0]
          ) {
            this.$message({
              type: "warning",
              message: "您的废危品经营许可证开始日期不可晚于今日，不可提交！",
            });
          } else {
            let { pictureName, validityPeriod, ...args } = this.ruleForm;
            args.manuId = this.userInfo.manuId;
            args.expirationDateStart = validityPeriod[0];
            args.expirationDateEnd = validityPeriod[1];
            pictureName.forEach((item) => {
              args.objectName =
                `/apply/application/${this.userInfo.manuId}/` + item.name;
            });
            args.authenticationName = "废危品经营许可证";
            insertManuQualifications(args).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "提交成功！",
                });
                this.resetForm();
                this.getStatus();
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            });
          }
        }
      });
    },

    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.open = false;
    },
  },
};
</script>

<style lang="less" scoped>
.EnterpriseQualification {
  width: 342px;
  padding: 20px 0 0 30px;
  .qualificationBg {
    width: 100%;
    height: 226px;
    background-image: url("../../assets/images/AccountSettings/qualifications.png");
    background-size: 100% 100%;
    border-radius: 6px;
  }
  .label {
    font-size: 14px;
    color: #999;
    margin-top: 10px;
    text-align: center;
  }
  .btnClass {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .pub {
      width: 98px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
    .start {
      background-color: #0dac65;
    }
    .inProgress {
      background-color: #ccc;
      cursor: default;
    }
    .verified {
      background-color: #0dac65;
      cursor: default;
    }
    .reStart {
      background-color: #ff7f00;
    }
  }
  .failReason {
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    color: #ee0e20;
    word-break: break-all;
  }
  .termValidity{
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
  }
}
</style>

<style scoped>
.el-upload__tip {
  margin-top: 0;
  line-height: 20px;
}
</style>

<style scoped>
/deep/ .el-upload-dragger {
  width: 460px !important;
}
</style>
